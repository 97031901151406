<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      ref="sidebar-create"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar empresa' : 'Editar empresa'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-form method="POST">
            <validation-observer ref="simpleRules">
            <b-form-input class="hidden" name="_token" :value="csrf" />
            <b-form-group
              label="Nome da empresa:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="nome da empresa"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  placeholder="Ex: Empresa X"
                  v-model="dataCompany.company_name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Nome fantasia:"
              label-for="i-fantasy"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="nome fantasia"
                rules="required"
              >
                <b-form-input
                  id="i-fantasy"
                  placeholder="Ex: Nome fantasia"
                  v-model="dataCompany.fantasy_name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CNPJ:"
              label-for="i-cnpj"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="CNPJ"
                rules="required"
              >
                <b-form-input
                  id="i-cnpj"
                  v-model="dataCompany.cnpj"
                  v-mask="'##.###.###/####-##'"
                  placeholder="Ex: 12.345.678/0001-99"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="* Categoria:"
              label-for="i-category"
              class="pl-1 pr-1"
            >
              <b-form-select
                v-model="dataCompany.category"
                :options="categoryOptions"
              />
            </b-form-group>
            <b-form-group
              label="Telefone:"
              label-for="i-phone"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="telefone"
                rules="required"
              >
                <b-form-input
                  id="i-phone"
                  v-model="dataCompany.phone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  placeholder="Ex: (XX) 9 9999-9999"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Domínio Classroom:"
              label-for="i-classroom"
              class="pl-1 pr-1"
            >
              <b-form-input
                id="head"
                v-model="dataCompany.classroom_domain"
                placeholder="Ex: emailempresa@gmail.com"
                name="i-classroom"
                class="form-control"
              />
            </b-form-group>
            <div class="divider my-2" v-if="Object.entries(this.dataProp).length === 0">
              <div class="divider-text">
                Gestor
              </div>
            </div>
            <b-form-group
              v-if="Object.entries(this.dataProp).length === 0"
              label="Nome:"
              label-for="i-name-user"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="name-user"
                  v-model="dataCompany.user.name"
                  placeholder="Nome"
                  name="i-name-user"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="Object.entries(this.dataProp).length === 0"
              label="Email:"
              label-for="i-email-company"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="dataCompany.user.email"
                  placeholder="Ex: emailempresa@gmail.com"
                  name="i-email-company"
                  class="form-control"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="Object.entries(this.dataProp).length === 0"
              label="CPF (Usuário):"
              label-for="i-cpf-company"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="CPF"
                rules="required"
              >
                <b-form-input
                  id="cpf"
                  v-mask="'###.###.###-##'"
                  v-model="dataCompany.user.cpf"
                  placeholder="123.456.789-01"
                  name="i-cpf-compnay"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="Object.entries(this.dataProp).length === 0"
              label="Senha:"
              label-for="i-password"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="senha"
                rules="required|password"
              >
                <b-form-input
                  id="password"
                  v-model="dataCompany.user.password"
                  placeholder="**************"
                  name="i-password"
                  class="form-control"
                  type="password"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row style="margin-right: 0rem !important;" class="mb-1">
              <b-col lg="3" sm="6" class="mr-1 ml-1">
                <b-button
                  variant="relief-primary"
                  @click="storeContract((value = 1))"
                  :disabled="dataCompany.company_name == null"
                >
                  {{this.dataCompany.id == null ? 'Salvar' : 'Editar'}}
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="closeSidebar()"
                  block
                >
                  Fechar
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      icon: null,
      dataCompany: {
        company_name: null,
        fantasy_name: null,
        cnpj: null,
        category: 'publica',
        phone: null,
        classroom_domain: null,
        status: 'ativo',
        user: {
          name: null,
          username: null,
          cpf: null,
          email: null,
          password: null
        }
      },
      categoryOptions: [
        { value: 'publica', text: 'Pública' },
        { value: 'privada', text: 'Privada' }
      ],

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("companies", ["companies"])
  },
  methods: {
    ...mapActions("companies", ["store", "update"]),
      storeContract(value) {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.isLoadingStore = true
            if (Object.entries(this.dataProp).length === 0) { 
              this.dataCompany.user.username = this.dataCompany.user.cpf;
              this.store(this.dataCompany).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cadastrado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })

                this.initValues();
                this.closeSidebar();
              })
              .finally(() => {
                this.isLoadingStore = false
              })
            } else {
              this.dataCompany.user.username = this.dataCompany.user.cpf;
              this.update(this.dataCompany).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Atualizado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'info'
                  }
                })

                
              })
              .finally(() => {
                this.isLoadingStore = false
                this.closeSidebar()
              })
            }
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro de validação!',
                icon: 'AlertTriangleIcon',
                text: 'Por favor preencha todos os campos obrigatorios.',
                variant: 'danger'
              }
            })
          }
        })
        
    }, 
    initValues(){
      this.dataCompany.id = null;
      this.dataCompany.company_name = null;
      this.dataCompany.fantasy_name = null;
      this.dataCompany.cnpj = null;
      this.dataCompany.category = 'publica';
      this.dataCompany.phone = null;
      this.dataCompany.classroom_domain = null;
      this.dataCompany.status = null;
      this.$nextTick(() => this.$refs.simpleRules.reset());
    }, 
    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      if (Object.entries(this.dataProp).length === 0) { 
        this.initValues(); 
        //this.$validator.reset(); 
      } else { 
        let { id, company_name, fantasy_name, cnpj, category, phone, classroom_domain, status } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataCompany.id = id;
        this.dataCompany.company_name = company_name;
        this.dataCompany.fantasy_name = fantasy_name;
        this.dataCompany.cnpj = cnpj;
        this.dataCompany.category = category;
        this.dataCompany.phone = phone;
        this.dataCompany.classroom_domain = classroom_domain;
        this.dataCompany.status = status;
        // this.initValues(); 
      } 
    }
  }
  }
}
</script>