<template>
  <b-card>
      <b-row v-show="companies.length > 0 || search != null">
        <b-col md="7">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Exibir"
                  label-for="h-exibir"
                  label-cols-md="4"
                  class="text-muted"
                >
                  <b-form-select
                    size="sm"
                    class="form-content"
                    v-model="perPage"
                    :options="perPageOptions"
                    :disabled="isLoading"
                  />
                </b-form-group>
              </b-col>
            </b-row>
        </b-col>
        <b-col md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Pesquisar"
               v-model="search"
              debounce="800"
              type="search"
              size="sm"
              :disabled="isLoading"
            />
          </b-input-group>
        </b-col>
        <b-col md="2">
          <b-button v-if="$can('read', 'ADMIN')" block variant="relief-primary" @click="addNew" v-b-toggle.sidebar-create size="sm">
            Nova Empresa
          <feather-icon icon="BoxIcon" />
          </b-button>
        </b-col>
      </b-row>
      <div v-show="companies.length > 0 || search != null">
        <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <b-table-simple
          striped
          responsive
          class="mt-2"
          id="tableCompanies"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome empresa</b-th>
              <b-th>Nome fantasia</b-th>
              <b-th>CNPJ</b-th>
              <b-th>Categoria</b-th>
              <b-th>Situação</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr  
              v-for="(tr, indextr) in companies" 
              :key="indextr">
              <b-td>
                {{ tr.company_name }}
              </b-td>
              <b-td>
                {{ tr.fantasy_name}}
              </b-td>
              <b-td class="text-center">
                {{ tr.cnpj }}
              </b-td>
              <b-td class="text-center">
                {{ tr.category=='publica' ? 'pública' : tr.category }}
              </b-td>
              <b-td class="text-center">
                {{ tr.status }}
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="saveId(tr.id)"
                  :to="{ path: '/empresa/' + tr.id }"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-toggle.sidebar-create
                  @click="updateDetail(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showDetail(tr.id)"
                  v-b-modal.modal-delete
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div class="row" v-show="totalRows > 10">
            <div class="col-6 col-md-9"> 
              Exibindo {{ perPage }} de {{ totalRows }} registros
            </div>
            <div class="col-6 col-md">
              <b-pagination
                v-model="currentPage"
                class="text-center float-right"
                :per-page="perPage"
                :total-rows="totalRows"
                aria-controls="tableSchools"
                size="sm"
                @change="handlePageChange"
              />
            </div>
          </div>

      </b-overlay>
    </div>

    <div class="text-center" v-show="companies.length == 0 && search == null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <no-data />
        <b-button variant="relief-primary" @click="addNew" v-b-toggle.sidebar-create size="sm">
          Nova Empresa
          <feather-icon icon="BoxIcon" />
        </b-button>
      </b-overlay>
    </div>

    <create-edit :key="count" :dataProp="sidebarData"/>
      
    <!-- Modal Delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"  
      ref="modal-delete"
      centered
      title="Deletar"
      v-if="companyModel != null"
    >
      <b-card-text>
        <p>Você está preste a deletar esta empresa do sistema: <b>{{ companyModel.company_name }}</b></p>
        Por favor, confirme a exclusão antes de prosseguir. 
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
        >
          Concordo em excluir esta empresa
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deleteCompany(companyModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEdit from "./CreateEdit.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    CreateEdit,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,

      DeleteStatus: false,
      companyModel: null,

      sidebarData: {
        id: null,
        company_name: null,
        fantasy_name: null,
        cnpj: null,
        category: 'publica',
        phone: null,
        classroom_domain: null,
        status: 'ativo'
      },
      sidebarActive: false, 

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("companies", ["companies"]),
  },
  methods: {
    ...mapActions("companies", ["update"]),

    saveId(id){
      localStorage.removeItem('company_id')
      localStorage.setItem('company_id', id)
    },

    addNew() {
      this.sidebarData = {}; 
      this.count++;
    },
    
    fetchCompanies(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("companies/fetchCompanies", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchCompanies(true, value)
    },

    showDetail(id){
      this.companyModel = this.$store.getters['companies/getCompanyById'](id)
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deleteCompany(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("companies/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.fetchCompanies()
          this.$refs['modal-delete'].hide()
        })
    },

    updateDetail(id){
      this.count++;
      this.updateModel = this.$store.getters['companies/getCompanyById'](id);
      this.sidebarData.id = this.updateModel.id;
      this.sidebarData.company_name = this.updateModel.company_name;
      this.sidebarData.fantasy_name = this.updateModel.fantasy_name;
      this.sidebarData.cnpj = this.updateModel.cnpj;
      this.sidebarData.category = this.updateModel.category;
      this.sidebarData.phone = this.updateModel.phone;
      this.sidebarData.classroom_domain = this.updateModel.classroom_domain;
      this.sidebarData.status = this.updateModel.status;
    },

    updateCompanies() {
        this.update({
        ...this.updateCompanies,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.$refs['sidebar-update'].hide()
        this.fetchCompanies()
      });
    }
  },
  created() {
    this.fetchCompanies();
  },
  watch: {
    perPage(){
      this.fetchCompanies()
    },
    search(){
      this.currentPage = 1;
      this.fetchCompanies(true, 1);
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>